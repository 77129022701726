.footer-content {
  background-color: #d7ae72; /* Warm neutral tone for elegance */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: 'Proxima Nova', sans-serif;
  color: #5d0c0d;
  width: 100%;
  padding: 20px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.trademark {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 85%;
  max-width: 1200px;
  flex-wrap: wrap;
  gap: 15px;
}

.left-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  font-weight: 400;
  flex: 1;
  gap: 10px;
}

.right-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 600;
  flex: 1;
  flex-wrap: wrap;
  gap: 10px;
}

.right-container a {
  color: #5d0c0d;
  text-decoration: none;
  transition: color 0.3s ease;
}

.right-container a:hover {
  color: #3e2009; /* Darker shade for hover effect */
  text-decoration: underline;
}

/* Tablet Devices */
@media screen and (max-width: 768px) {
  .trademark {
    flex-direction: column;
    text-align: center;
    gap: 20px;
  }

  .left-container,
  .right-container {
    width: 100%;
    justify-content: center;
    font-size: 13px;
  }

  .right-container {
    gap: 15px;
  }
}

/* Mobile Devices */
@media screen and (max-width: 480px) {
  .footer-content {
    padding: 15px 10px;
  }

  .trademark {
    width: 95%;
  }

  .left-container,
  .right-container {
    font-size: 12px;
    gap: 10px;
  }

  .right-container a {
    display: block;
    margin: 5px 0; /* Add spacing between links on smaller devices */
  }
}

/* Extra Small Devices */
@media screen and (max-width: 320px) {
  .footer-content {
    padding: 10px 5px;
  }

  .left-container,
  .right-container {
    font-size: 11px;
  }
}
