.contact-us-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  width: 100%;
  
}

.contact-us-container {    
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.contact-rightContainer {    
  width: 50%;
  margin-left: 5rem;
  display: flex;
  flex-direction: column;
}

.contact-rightContainer h2 {    
  font-size: 3em;
}

.contact-leftContainer img {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  pointer-events: none;
  transition: transform 0.2s ease;
  object-fit: cover;
}

.contact-leftContainer {
  width: 50%;
}

.contact-upperContainer {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  padding: 40px;    
  background-color: #F6F5F1;
}

.contact-us {
  border-radius: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 40px;
  width: 60%;
  max-width: 500px;
}

.contact-us h2 {
  text-align: center;
  margin-bottom: 30px;
  color: #5d0c0d;
  font-size: 26px;
  font-weight: 600;
}

h4 {
  text-align: center;
  margin-bottom: 30px;
  color: #5d0c0d;
  font-size: 20px;
  font-weight: 600;
}

.contact-us form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

.contact-us label {
  margin-bottom: 8px;
  font-weight: bold;
  color: black;
  display: block;
}

.contact-us input,
.contact-us textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  box-sizing: border-box;
}

.contact-us input:focus,
.contact-us textarea:focus {
  border-color: #5d0c0d;
  box-shadow: 0 0 5px rgba(255, 102, 165, 0.5);
  outline: none;
}

.contact-us textarea {
  resize: vertical;
  min-height: 120px;
}

.contact-us button {
  padding: 12px 20px;
  border: none;
  border-radius: 25px;
  background: #5d0c0d;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
  align-self: center;
  width: auto;
  min-width: 150px;
}

.contact-us button:hover {
  background: #5d0c0d;
  transform: translateY(-2px);
}

.form-status {
  margin-top: 20px;
  text-align: center;
  color: #28a745;
  font-weight: bold;
}

.icon-margin {
  margin-top: 60px;
  margin-right: 30px;;
}

.contact-midContainer {
  margin: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.contact-howItWorks {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.contact-howItWorks h2 {
  color: black;
  font-weight: bold;
  font-size: 1.4rem;
}

.contact-midContainer img {
  margin-right: 30px;
  width: 100%; /* makes the image fill the container width */
  height: 200px; /* sets the height to a specific value */
  object-fit: cover; /* ensures the image covers the entire box, cropping if necessary */
}

.contact-lowerContainer {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin-top: 2rem;
}

.contact-video {
  width: 100%;
}

/* Tablet Responsive Styles */
@media screen and (max-width: 1024px) {
  .contact-rightContainer {
      margin-left: 2rem;
  }

  .contact-rightContainer h2 {
      font-size: 2.5em;
  }

  .contact-us {
      width: 80%;
      padding: 30px;
  }
}

/* Mobile Responsive Styles */
@media screen and (max-width: 768px) {
  .contact-upperContainer {
      flex-direction: column;
      padding: 20px;
  }

  .contact-leftContainer,
  .contact-rightContainer {
      width: 100%;
      margin: 0;
  }

  .contact-rightContainer h2 {
      font-size: 2em;
      text-align: center;
      margin-top: 20px;
  }

  .contact-us {
      width: 90%;
      padding: 20px;
  }

  .contact-lowerContainer {
      flex-direction: column;
      width: 90%;
  }

  .icon-margin {
      margin-top: 50px;
  }

  .contact-midContainer {
      flex-direction: column;
      align-items: center;
  }

  .contact-midContainer img {
      margin: 15px;
  }
}

/* Small Mobile Devices */
@media screen and (max-width: 480px) {
  .contact-us-main-container {
     
  }

  .contact-upperContainer {
      padding: 15px;
  }

  .contact-rightContainer h2 {
      font-size: 1.8em;
  }

  .contact-us h2 {
      font-size: 22px;
  }

  h4 {
      font-size: 18px;
  }

  .contact-us input,
  .contact-us textarea {
      font-size: 14px;
      padding: 10px;
  }

  .contact-us button {
      padding: 10px 16px;
      font-size: 14px;
      min-width: 120px;
  }

  .contact-howItWorks h2 {
      font-size: 1.2rem;
  }
}