.team-section {
    padding: 50px 20px;
    width: 100%;
    text-align: center;
  }
  
  .team-title {
    font-size: 2rem;
    font-weight: bold;
    color: #5d0c0d;
    margin-bottom: 10px;
  }
  
  .team-description {
    font-size: 1.2rem;
    color: #0a0101;
    margin-bottom: 30px;
  }
  
  .team-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .team-card {
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    width: 250px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .team-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  }
  
  .team-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  
  .team-info {
    padding: 15px;
  }
  
  .team-name {
    font-size: 1.4rem;
    font-weight: bold;
    color: #5d0c0d;
    margin-bottom: 5px;
  }
  
  .team-designation {
    font-size: 1rem;
    color: #777;
  }
  