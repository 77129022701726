.follow-us-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
  color: #5d0c0d;
  border-bottom: 1px solid #d7d7d7;
  background-color: #fff;
  font-family: "Proxima Nova", sans-serif;
  padding: 20px 10px;
}

.upper-container {
  width: 90%;
  max-width: 1200px;
  text-align: left; /* Ensure the container aligns text to the left */
  padding: 20px 0;
}

.lower-container {
  width: 90%;
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
}

.main-heading-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: left; /* Align the content inside the container to the left */
}

.heading {
  font-size: 24px;
  font-weight: 800;
  color: #5d0c0d;
}

.small-heading {
  font-size: 18px;
  font-weight: 400;
  color: #5d0c0d;
}

.text {
  font-size: 16px;
  color: #4b5563;
  line-height: 1.6;
}

.follow-left-section {
  flex: 1 1 45%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  text-align: left;
}

.address-section {
  font-size: 15px;
  line-height: 1.5;
}

.follow-right-section {
  flex: 1 1 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.follow-InstaCode img {
  width: 70%;
  max-width: 250px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.social-icons {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  align-items: center;
}

.social-icons a {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  color: #fff;
  text-decoration: none;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;
}

.social-icons a:hover {
  transform: translateY(-2px);
}

.icon.facebook {
  background-color: #3b5998;
  padding: 5px;
  border-radius: 50%;
}

.icon.instagram {
  background-color: #e1306c;
  padding: 5px;
  border-radius: 50%;
}

.icon.pinterest {
  background-color: #bd081c;
  padding: 5px;
  border-radius: 50%;
}

.followus-fa-span {
  color: #5d0c0d;
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
}

/* Responsive Design */
@media (max-width: 768px) {
  .upper-container {
    text-align: center;
    padding: 15px 0;
  }

  .lower-container {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .follow-left-section,
  .follow-right-section {
    width: 100%;
    text-align: center;
  }

  .social-icons a {
    justify-content: center;
    width: 80%;
  }

  .follow-InstaCode img {
    width: 50%;
  }
}

@media (max-width: 480px) {
  .heading {
    font-size: 20px;
  }

  .small-heading {
    font-size: 16px;
  }

  .text {
    font-size: 14px;
  }

  .social-icons a {
    font-size: 16px;
  }

  .follow-InstaCode img {
    width: 70%;
  }
}
