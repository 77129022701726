.video-container {
    position: relative;
    width: 100%;
    height: 500px; /* Default height for desktop */
  }
  
  .video-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;  /* Ensure the video fills the container without distortion */
  }
  
  /* Media Query for Desktop */
  @media screen and (min-width: 1024px) {
    .video-container {
      height: 600px;  /* Adjust height for desktop */
    }
  }
  
  /* Media Query for Mobile (for smaller screens) */
  @media screen and (max-width: 768px) {
    .video-container {
      height: 400px;  /* Adjust height for mobile */
    }
  }
  
  @media screen and (max-width: 480px) {
    .video-container {
      height: 300px;  /* Adjust height for smaller mobile devices */
    }
  }
  