/* General Styles for the Gallery Page */
.gallery-page {
  width: 90%;
  margin-top: 1em;
}

.gallery-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gallery-title {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1.5rem;
  font-weight: bold;
  color: #5d0c0d;
}

.gallery-section {
  margin-bottom: 40px;
}

/* Horizontal Scroll Container */
.image-scroll-container {
  display: flex;
  gap: 15px; /* Space between images */
  padding: 10px 0;
  overflow-x: auto; /* Allow horizontal scroll */
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch; /* Add smooth scrolling for mobile */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
  cursor: grab; /* Change cursor to indicate scrollability */
}

.image-scroll-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Chrome/Safari */
}

/* Image Card Styling */
.item-gallery {
  padding: 30px;
  transition: transform 0.3s ease;
}

.image-card {
  width: 250px;  /* Adjust width for mobile */
  height: 200px;  /* Fixed height for cards */
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

/* Hover Effect for Image Cards */
.image-card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.image-card:hover img {
  transform: scale(1.1); /* Zoom in the image on hover */
}

/* Keyframes for image card entry animation */
@keyframes card-entry {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
  border-radius: 10px;
  background-color: white;
  padding: 10px;
}
.modal-content {
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
  border-radius: 10px;
  background-color: white;
  padding: 10px;
}


.modal-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: contain;
  cursor: pointer;
}
/* Carousel Styles */
.inner-carousel {
  display: flex;
}

.carousel {
  cursor: grab;
  overflow: hidden;
}

.item-data {
  padding: 30px;
  transition: transform 0.3s ease;
}

.item-data:hover {
  transform: scale(1.2); /* Adjust the scale as needed */
}

/* Mobile Responsive Styles */
@media screen and (max-width: 768px) {
  .gallery-title {
    font-size: 1.5rem; /* Slightly smaller title on mobile */
  }

  .image-card {
    width: 300px;  /* Increased width for mobile devices */
    height: 250px;  /* Increased height for mobile devices */
  }


  .gallery-page {
    width: 100%;
    margin-top: 2em;
  }

  /* Adjust scroll container for smaller screens */
  .image-scroll-container {
    padding: 10px 0;
    gap: 10px;
  }



  /* Modal adjustments for smaller devices */
  .modal-content {
    max-width: 90%;
    max-height: 90%;
  }
}

/* Small Mobile Devices */
@media screen and (max-width: 480px) {
  .gallery-title {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  .image-card {
    width: 250px; /* Even smaller cards for mobile */
    height: 250px;
  }

  .gallery-container {
    padding: 10px;
  }
}

/* Add smooth transition for touch and hover devices */
@media (hover: hover) {
  .image-card:hover .modal-image {
    opacity: 0.8;
  }
}

