/* Loading screen container */
.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #fff8ec; /* Semi-transparent dark background */
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  
  /* Loader content container */
  .loader-content {
    text-align: center;
  }
  
  /* Loading text */
  .loader-text {
    margin-top: 20px;
    color: #5d0c0d;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    animation: pulse 1.5s infinite;
  }
  
  /* Pulse animation for text */
  @keyframes pulse {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
  