/* SuccessModal.css */
/* Ensure modal overlay covers the entire page */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: flex-start; /* Align the modal to the top */
    justify-content: center;
    padding-top: 2rem; /* Add spacing from the top */
    z-index: 1050;
  }
  
  /* Style the modal card */
  .modal-card {
    position: relative;
    max-width: 20rem;
    width: 100%;
    background: white;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1100;
    margin: 0 auto;
  }


  .modal-content {
    text-align: center;
  }
  
  .icon-container {
    width: 4rem;
    height: 4rem;
    background-color: #d1fae5; /* Light green background */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 1rem;
  }
  
  .success-icon {
    width: 2rem;
    height: 2rem;
    color: #10b981; /* Green color */
  }
  
  .modal-message {
    font-size: 1.125rem;
    font-weight: 600;
    color: #1f2937; /* Dark gray */
    margin-bottom: 1rem;
  }
  
  .close-button {
    background-color: #3b82f6; /* Blue color */
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    transition: background-color 0.2s;
  }
  
  .close-button:hover {
    background-color: #2563eb; /* Darker blue */
  }
  