.about-us {
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  letter-spacing: 0.5px;
  background-color: #f9f6f4;
  font-family: "Proxima Nova", sans-serif;
}

/* Ensure the container takes full width */
.aboutus-bcimage {
  width: 100%;
  height: auto; /* Allow image height to adjust based on the aspect ratio */
  display: flex;
  justify-content: center; /* Center the image horizontally */
  overflow: hidden; /* Hide any overflow if the image is larger than the container */
}

/* Image styles */
.aboutus-bcimage img {
  width: 100%;
  height: 100%; /* Make the image fill the container height */
  object-fit: cover; /* Ensures image covers the area without stretching, but without cropping */
  object-position: top; /* Aligns the image to the top, ensuring no cropping from the top */
}


.header-aboutus h1 {
  margin-top: 2em;
  font-size: 2.5em;
  color: #5d0c0d;
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 90%;
  text-align: left;
  margin-top: 40px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.header-data {
  width: 45%;
  color: #000;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

h2 {
  color: #5d0c0d;
  font-size: 2.5em;
  margin-bottom: 15px;
}

.header-data p {
  font-size: 1em;
  line-height: 1.6;
  margin: 10px 0;
}

.watch-video-btn {
  color: #fff;
  background-color: #5d0c0d;
  border: none;
  padding: 12px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  font-size: 1em;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.watch-video-btn:hover {
  background-color: #d7ae72;
  transform: scale(1.05);
}

.info {
  width: 45%;
  font-size: 1em;
  line-height: 1.6;
  color: #565656;
}

.other-ventures {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
}

.inner-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.venture-item {
  width: 300px;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.venture-item img {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
}

.venture-item:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.venture-name {
  margin-top: 10px;
  font-size: 1.2em;
  font-weight: bold;
  color: #5d0c0d;
  text-align: center;
}

.ourValues-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 90%;
  margin-top: 40px;
}

.left-container-aboutus {
  flex: 1;
  margin-right: 15px;
}

.left-container-aboutus p {
  font-size: 1em;
  line-height: 1.6;
  color: #000;
  text-align: left;
}

.right-container-aboutus {
  flex: 1;
  margin-left: 15px;
}

.value-item {
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.value-item:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.value-header {
  display: flex;
  align-items: center;
}

.value-header h4 {
  margin-left: 10px;
  font-weight: bold;
  margin-bottom: 20px; 
  color: #5d0c0d;
}

.value-info {
  margin-top: 1px;
  color: #555;
  font-size: 0.9em;
  line-height: 1.4;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .aboutus-bcimage {
    margin-top: 4em;;
    height: 200px;
  }
  .aboutus-bcimage img {
    object-position: center; /* Center the image for mobile */
  }

  .header-aboutus h1 {
   
    font-size: 2.5em;
    color: #5d0c0d;
    text-align: center; /* Ensures text is centered */
    width: 100%; /* Ensures it takes full width for mobile responsiveness */
  }
  .content {
    flex-direction: column;
    align-items: center;
    padding: 15px;
  }
  .header-data, .info  {
    width: 100%;
    text-align: left; /* Align text to the left */
    margin-bottom: 20px;
  }

  

  .ourValues-container {
    flex-direction: column;
  }

  .left-container-aboutus,
  .right-container-aboutus {
    width: 100%;
    margin: 10px 0;
  }

  .venture-item {
    width: 90%;
    margin: 0 auto;
  }

  h2 {
    font-size: 2em;
  }

  .watch-video-btn {
    font-size: 0.9em;
    padding: 10px 15px;
    align-self: center; /* Centers the button horizontally */
    margin-top: 15px;   /* Adds some space above the button */
  }
}

@media screen and (max-width: 480px) {
  .aboutus-bcimage {
    height: 150px;
  }
  .aboutus-bcimage img {
    object-position: center; /* Center the image for mobile */
  }

  h2 {
    font-size: 1.8em;
  }

  .watch-video-btn {
    font-size: 0.8em;
    padding: 8px 12px;
    align-self: center; /* Centers the button horizontally */
    margin-top: 15px;   /* Adds some space above the button */
  }

  .venture-name {
    font-size: 1em;
  }

  .value-info {
    font-size: 0.8em;
  }
}
