.homeContainer {
  min-height: 600px;    
  width: 100%;
  margin: 0 auto;    
  transition: transform 0.3s, box-shadow 0.3s;
  overflow: hidden;
}

.home {
  width: 100%;
}

.homeContainer img {    
  width: 80%; 
  height: auto;
  margin-top: 5px;
  display: block;
  max-width: 100%;
}

.home h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  line-height: 1.2;
}

.inhouse-service-container {
  margin: 2em;
}

.home p {
  font-size: 1.2em;
  line-height: 1.6;
}

.home h3 {
  color: #5d0c0d;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 22px;
  display: block;
  line-height: 1.3;
}

video {
  width: 100%;   
  height: 100%;
  object-fit: cover;
}

/* Tablet Responsive Styles */
@media screen and (max-width: 1024px) {
  .homeContainer {
      min-height: 500px;
  }

  .homeContainer img {
      width: 90%;
  }

  .home h1 {
      font-size: 2.2em;
  }

  .home h3 {
      font-size: 20px;
  }

  .inhouse-service-container {
      margin: 1.5em;
  }
}

/* Mobile Responsive Styles */
@media screen and (max-width: 768px) {
  .homeContainer {
      min-height: 400px;
  }

  .homeContainer img {
      width: 95%;
      margin-top: 3px;
  }

  .home h1 {
      font-size: 1.8em;
      margin-bottom: 15px;
  }

  .home p {
      font-size: 1.1em;
  }

  .home h3 {
      font-size: 18px;
      margin-bottom: 15px;
  }

  .inhouse-service-container {
      margin: 1em;
  }

  video {
      height: auto;
      min-height: 300px;
  }
}

/* Small Mobile Devices */
@media screen and (max-width: 480px) {
  .homeContainer {
      min-height: 300px;
  }

  .homeContainer img {
      width: 100%;
      margin-top: 2px;
  }

  .home h1 {
      font-size: 1.5em;
      margin-bottom: 12px;
  }

  .home p {
      font-size: 1em;
      line-height: 1.4;
  }

  .home h3 {
      font-size: 16px;
      margin-bottom: 12px;
  }

  .inhouse-service-container {
      margin: 0.8em;
  }

  video {
      min-height: 250px;
  }
}

/* Extra Small Devices */
@media screen and (max-width: 320px) {
  .homeContainer {
      min-height: 250px;
  }

  .home h1 {
      font-size: 1.3em;
  }

  .home h3 {
      font-size: 15px;
  }

  .inhouse-service-container {
      margin: 0.5em;
  }

  video {
      min-height: 200px;
  }
}