/* Wedding Packages Container */
.wedding-packages-container {
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wedding-content {
  width: 80%;
  max-width: 1200px; /* Ensures better readability on larger screens */
}

/* Title Styling */
.wedding-packages-title {
  text-align: center;
  font-size: 2rem;
  color: #5d0c0d;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wedding-packages-title .icon {
  margin: 0 0.5rem;
}

/* Grid Layout for Cards */
.wedding-packages-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
}

/* Responsive Adjustments for Grid */
@media (max-width: 992px) {
  .wedding-packages-grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;
  }
}

@media (max-width: 768px) {
  .wedding-packages-grid {
      grid-template-columns: 1fr;
      gap: 1.5rem;
  }
}

/* Card Styling */
.wedding-package-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%; /* Adjusted for grid auto-scaling */
  max-width: 350px; /* Limits card size for larger screens */
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease;
  background: #fff;
  margin: 0 auto;
}

.wedding-package-card:hover {
  transform: scale(1.05);
}

/* Image Styling */
.card-header img {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

/* Card Content */
.card-content {
  padding: 1rem;
}

.card-title {
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #b91d4a;
}

.card-description {
  font-size: 0.9rem;
  color: black;
}

/* Features Section */
.features {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: left;
}

.feature {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  color: black;
  font-size: 0.9rem;
}

/* Card Footer */
.card-footer {
  margin-top: auto;
  padding: 1rem;
  background-color: #fdf2f4;
  border-top: 1px solid #ddd;
}

.footer-contents {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.price {
  font-size: 1.2rem;
  font-weight: bold;
  color: #5d0c0d;
}

.price-label {
  font-size: 0.9rem;
  margin-left: 5px;
  color: #0e1111;
}

/* Book Button */
.book-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #5d0c0d;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1.5rem;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.book-button:hover {
  background-color: #4b0a0b;
}

.book-button .icon {
  margin-right: 0.5rem;
}

/* Mobile-Friendly Adjustments */
@media (max-width: 640px) {
  .wedding-content {
      width: 90%;
  }

  .wedding-packages-title {
      font-size: 1.5rem;
  }

  .card-title {
      font-size: 1.1rem;
  }

  .card-description {
      font-size: 0.8rem;
  }

  .price {
      font-size: 1rem;
  }

  .book-button {
      font-size: 0.8rem;
      padding: 0.4rem 1rem;
  }
}
