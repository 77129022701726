/* Base Navbar Styles */
.nav-main-container {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: proxima-nova, sans-serif;
  height: 80px;
  width: 100%;
  top: 0;
  z-index: 1000;
  transition: all 0.3s ease;
  position: fixed;  /* Keeps navbar fixed on top */
}

.nav-wrapper {
  width: 80%;
  margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.brand-logo img {
  height: 60px;
  width: auto;
  
  transition: height 0.3s ease;
}

.nav-wrapper ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  transition: opacity 0.3s ease;
}

.nav-wrapper ul li {
  margin-left: 20px;
}

.nav-wrapper ul li a {
  color: #5d0c0d;
  text-decoration: none;
  font-weight: 700;
  transition: color 0.3s ease;
  font-size: 15px;
}

.nav-wrapper ul li a:hover {
  color: #8c1b1b;
  opacity: 0.8;
}

/* Mobile Menu Button */
.mobile-menu-btn {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  z-index: 1001;
  transition: transform 0.3s ease;
}

.mobile-menu-btn span {
  display: block;
  width: 25px;
  height: 3px;
  background-color: #5d0c0d;
  margin: 5px 0;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

/* Media Queries for Mobile */
@media screen and (max-width: 768px) {
  .nav-wrapper {
    width: 90%;
    position: relative;
  }

  .mobile-menu-btn {
    display: block;
  }

  .nav-wrapper ul {
    display: none;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: white;
    flex-direction: column;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px 0;
    transition: all 0.3s ease;
  }

  .nav-wrapper ul.active {
    display: flex;
  }

  .nav-wrapper ul li {
    margin: 0;
    text-align: center;
    padding: 15px 0;
    transition: background-color 0.3s ease;
  }

  .nav-wrapper ul li a {
    font-size: 16px;
    display: block;
    padding: 5px 0;
    color: #5d0c0d;
    transition: color 0.3s ease;
  }

  .nav-wrapper ul li a:hover {
    color: #8c1b1b;
  }

  .brand-logo img {
    height: 45px;
  }
}

/* Smaller Screens */
@media screen and (max-width: 480px) {
  .nav-wrapper {
    width: 95%;
  }

  .brand-logo img {
    height: 50px; /* Slightly increase size for very small screens */
  }

  .nav-wrapper ul li a {
    font-size: 14px;
  }
}

/* Mobile Menu Open Animation */
.mobile-menu-open {
  height: auto;
  transform: translateY(0);
}

.mobile-menu-btn.active span:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.mobile-menu-btn.active span:nth-child(2) {
  opacity: 0;
}

.mobile-menu-btn.active span:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}
