.testimonial-main-container {
    padding: 2rem;
   
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .header-testimonials {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 1.5rem;
    font-weight: bold;
    color: #5d0c0d;
  }
  
  .scrollbar-container {
    width: 100%;
    
   
    margin-bottom: 2rem;
    position: relative;
  }
  
  .scroll-progress-bar {
    height: 100%;
    background-color: #3b82f6;
    transform-origin: left center;
  }
  
  .testimonials-container {
    display: flex;
    overflow-x: scroll;
    gap: 2rem;
    scroll-snap-type: x mandatory;
    padding-bottom: 1rem;
  }
  
  .testimonial-card {
    flex-shrink: 0;
    width: 300px;
    cursor: grab;
    border-radius: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 1.0rem;
    text-align: center;
    scroll-snap-align: center;
    position: relative;
    overflow: hidden;
    margin-right: 40px;;
    margin-top: 10px;;
  }
  
  .quote-icon {
    position: absolute;
    top: 1rem;
    left: 1rem;
    color: #e5e5e5;
    font-size: 3rem;
    z-index: 0;
  }
  
  .testimonial-content {
    position: relative;
    z-index: 1;
  }
  
  .image-container {
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
  }
  
  .testimonial-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .testimonial-name {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #1f2937;
  }
  
  .testimonial-quote {
    font-style: italic;
    color: #4b5563;
    margin-bottom: 1rem;
  }
  
  .testimonial-footer {
    margin-top: auto;
  }
  
  .testimonial-position {
    color: #6b7280;
    margin-bottom: 1rem;
  }
  
  .testimonial-rating {
    display: flex;
    justify-content: center;
    gap: 0.25rem;
  }
  
  .star {
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .star.filled {
    color: #fbbf24;
  }
  
  .star.empty {
    color: #d1d5db;
  }
  