.item {
    height: 18rem; /* Adjusted for better responsiveness */
    min-width: 20rem; /* Smaller width for mobile */
    padding: 20px; /* Reduced padding for better spacing */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background-color: #fff; /* Optional: add a background color */
    border-radius: 0.5rem; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  }
  
  .item img {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    pointer-events: none;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    object-fit: cover; /* Ensure the image fits well */
  }
  
  .inner-carousel {
    display: flex;
    gap: 1rem; /* Space between items */
  }
  
  .carousel {
    cursor: grab;
    overflow: hidden;
    margin: 0 auto; /* Center the carousel container */
    padding: 1rem; /* Add some space around the carousel */
  }
  
  .item:hover {
    transform: scale(1.1); /* Subtle zoom effect */
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .item {
      height: 16rem; /* Slightly smaller for tablets */
      min-width: 18rem; /* Adjust width for smaller screens */
      padding: 15px; /* Reduced padding */
    }
  
    .carousel {
      padding: 0.5rem; /* Less padding on smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .item {
      height: 12rem; /* Smaller height for mobile devices */
      min-width: 14rem; /* Narrower items for mobile */
      padding: 10px; /* Compact padding */
    }
  
    .inner-carousel {
      gap: 0.5rem; /* Reduce the gap for tighter spacing */
    }
  }
  