/* Container Styling */
.container {
    padding: 2rem;
    background-color: #f9f9f9;
  }
  
  .title {
    text-align: center;
    font-size: 2rem;
    color: #b91d4a;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .title .icon {
    margin: 0 0.5rem;
  }
  
  /* Grid Layout */
  .cards-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;
  }
  
  /* Base Card Styling */
  .card {
    background: #ffffff;
    border: 2px solid #f3c6d8;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .card:hover {
    transform: scale(1.02);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  /* Card Header */
  .card-header {
    position: relative;
    height: 200px;
    overflow: hidden;
  }
  
  .card-header img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .card:hover .card-header img {
    transform: scale(1.1);
  }
  
  /* Card Content */
  .card-content {
    padding: 1rem;
    color:black;
  }
  
  .card-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: black;
    margin-bottom: 0.5rem;
  }
  
  .card-description {
    font-size: 0.9rem;
    color: #555;
    margin-bottom: 1rem;
  }
  
  .card-features {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .card-feature {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  /* Card Footer */
  .card-footer {
    border-top: 1px solid #f3c6d8;
    padding: 1rem;
    text-align: center;
    background-color: #fdf2f4;
  }
  
  .card-price {
    font-size: 1.2rem;
    font-weight: bold;
    color: #b91d4a;
    margin-bottom: 0.5rem;
  }
  
  .price-label {
    font-size: 0.9rem;
    color: #777;
  }
  
  .book-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #b91d4a;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 0.5rem 1.5rem;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .book-button:hover {
    background-color: black;
  }
  
  .book-button .icon {
    margin-right: 0.5rem;
  }
  