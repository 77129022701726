/* Testimonial Header Container */
.testimonial-header {
  position: relative;
  padding: 4rem 1rem;
  text-align: center;
  overflow: hidden;
}

.testimonial-header-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

/* Ensure content is centered on mobile */
.testimonialHeader-content {
  position: relative;
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.8s ease-out forwards;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically center */
  align-items: center; /* Horizontally center */
}

/* Text Elements */
.label {
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #5d0c0d;
  transform: translateY(20px);
  opacity: 0;
  animation: slideUp 0.6s ease-out 0.2s forwards;
}

.title {
  margin-top: 1rem;
  font-size: 2.5rem;
  font-weight: bold;
  color: #5d0c0d;
  transform: translateY(20px);
  opacity: 0;
  animation: slideUp 0.6s ease-out 0.4s forwards;
  text-align: center; /* Center text */
}

.subtitle-container {
  max-width: 42rem;
  margin: 1rem auto 0;
  text-align: center; /* Center text container */
}

.subtitle {
  font-size: 1.125rem;
  color: #4B5563;
  transform: translateY(20px);
  opacity: 0;
  animation: slideUp 0.6s ease-out 0.6s forwards;
}

/* Decorative Bars */
.decorative-bars {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1.5rem;
}

.bar {
  height: 4px;
  border-radius: 2px;
  opacity: 0;
  transform: scaleX(0);
  animation: scaleIn 0.6s ease-out 0.8s forwards;
}

.bar-long {
  width: 48px;
  background-color: #5d0c0d;
}

.bar-short {
  width: 12px;
  background-color: #5d0c0d;
}

/* Animations */
@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 0.2;
  }
}

@keyframes slideUp {
  from {
      transform: translateY(20px);
      opacity: 0;
  }
  to {
      transform: translateY(0);
      opacity: 1;
  }
}

@keyframes scaleIn {
  from {
      transform: scaleX(0);
      opacity: 0;
  }
  to {
      transform: scaleX(1);
      opacity: 1;
  }
}

/* Hover Effects */
.title:hover {
  transform: scale(1.02);
  transition: transform 0.3s ease;
}

.bar:hover {
  transform: scaleX(1.2);
  transition: transform 0.3s ease;
}

/* Responsive Design */
@media (max-width: 640px) {
  .title {
      font-size: 2rem;
  }
  
  .subtitle {
      font-size: 1rem;
  }
  
  .testimonialHeader-content {
      text-align: center;
      padding: 0 1rem; /* Add padding for smaller screens */
  }
  
  .subtitle-container {
      margin-top: 1rem;
      padding: 0 1rem; /* Add horizontal padding */
  }
}
