body {
  font-family: proxima-nova, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;  
  color: #333;
}
.header{     
  font-size: 2rem;
 text-align: center;
 margin-bottom: 1.5rem;
 font-weight: bold;
 color: #5d0c0d;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: proxima-nova, sans-serif;
}
.font-weight-600{
font-weight: 600;
}
.font-weight-400{
  font-weight: 400;
  }

.font-size-18{
  font-size: 18px;
}
.text-left{
  text-align: left;
}

.font-size-15{
  font-size: 15px;
}
.margin-top-10{
  margin-top: 10px;
}
.margin-bottom-10{
  margin-bottom:10px
}

.margin-bottom-20{
  margin-bottom:20px
}
.margin-left-100{
  margin-left:100px
}

.row-container{
  display: flex;
  flex-direction: row;
}

.column-container{
  display: flex;
  flex-direction: column;
}

.align-center{
  align-items: center;
}